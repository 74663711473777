export default [
  {
    key: "managerId",
    label: "field.employee",
    type: "nAsynSingleSelection",
    repository: "employee",
    selectionKey: "id",
    selectionLabel: "nameLabel",
    cols: 12,
    md: 12,
    lg: 6,
    clearable: true,
    query: { limit: 0 },
  },
];
