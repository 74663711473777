<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container @search="search" @reset="reset" :fields="searchFields" :default-visible="true">
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0" v-if="ds.id">
      <b-row class="p-2" align-v="center">
        <b-col>
          <feather-icon icon="AlertCircleIcon" class="text-danger" />
          <small class="text-danger">
            {{ $t("general.clickToAddSubordinate") }}
          </small>
        </b-col>
      </b-row>

      <organization-chart
        :datasource="ds"
        @node-click="userClick"
      ></organization-chart>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BLink,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import SearchInputs from "./searchInput";
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";

const EmployeeRepository = Repository.get("employee");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormGroup,
    BInputGroup,
    BLink,

    NSearchContainer,
    NSearchInput,
    OrganizationChart,
  },
  data() {
    return {
      ds: {},
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        managerId: Number(this.$route.query.managerId) || null,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
      },
    };
  },
  mounted() {
    if (this.params.managerId) {
      this.getData();
    }
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    search() {
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.updateQuerySting();
      this.getData();
    },
    userClick($event) {
      this.$router.push({
        name: "create-employee",
        query: {
          id: $event.id,
          type: "org-chart",
        },
      });
    },
    getData() {
      if (this.loading) {
        return;
      }

      if (!this.params.managerId) {
        this.ds = {};
        return;
      }

      this.loading = true;
      EmployeeRepository.orgChart(this.params.managerId)
        .then((response) => {
          const data = response.data.data;
          if (data.length) {
            const manager = data[0];
            data.splice(0, 1);
            this.mapOrgChart(manager, data);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    mapOrgChart(manager, suborndates) {
      suborndates = this.mapSubordinateTree(suborndates, manager.id);

      this.ds = {
        ...manager,
        children: [...suborndates],
      };
    },
    mapSubordinateTree(employees, managerId) {
      var tree = [];

      employees.forEach((element) => {
        if (element.managerId == managerId) {
          var children = this.mapSubordinateTree(employees, element.id);

          if (children.length) {
            element.children = children;
          }

          tree.push(element);
        }
      });

      return tree;
    },
  },
  setup() {
    const searchFields = SearchInputs;
    const permissionType = "employee";

    return {
      searchFields,
      permissionType,
    };
  },
};
</script>
